.client-container {
    width: 100%;
    max-width: 1180px;
    padding: 0 30px;
    margin: 32px auto;
}

.client-container h1 {
    color: #fff;
    margin-top: 50px;
    margin-bottom: 24px;
}

.choises {
    display: flex;
}

.choises select {
    width: 100%;
    height: 60px;
    color: #333;
    border: 1px solid #dcdce6;
    border-radius: 8px;
    padding: 0 24px;
    margin: 5px;
}