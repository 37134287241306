.clientes-container{
    width: 100%;
    max-width: 1180px;
    padding: 0 30px;
    margin: 32px auto;
}

.clientes-resume{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.clientes-container h1 {
    color: #fff;
    margin-top: 20px;
}

.clientes-container ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 24px;
    list-style: none;
}

.clientes-container ul li {
    background: #fff;
    padding: 24px;
    border-radius: 5px;
    position: relative;
    transition: all 0.2s;
}

.clientes-container ul li button {
    position: absolute;
    right: 24px;
    top: 24px;
    border: 0;
}

.clientes-container ul li button:hover {
    opacity: 0.8;
}

.clientes-container ul li strong {
    display: block;
    margin-bottom: 16px;
    color: #41414d;
}

.clientes-container ul li p + strong{
    margin-top: 12px;
}

.clientes-container ul li p{
    color: #737380;
    font-size: 16px;
    line-height: 21px;
}