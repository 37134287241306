.modules{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.modules button {
  width: 100%;
  height: 60px;
  background: #4BAE50;
  border-radius: 8px;
  border: 0;
  color: #fff;
  font-weight: 700;
  margin-top: 16px;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  font-size: 18px;
  line-height: 60px;
  transition: filter 0.2s;
}