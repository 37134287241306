.profile-container{
    width: 100%;
    max-width: 1180px;
    padding: 0 30px;
    margin: 32px auto;
}

.profile-container header {
    display: flex;
    align-items: center;
}

.profile-container header span {
    font-size: 20px;
    margin-left: 24px;
    color: #fff;
}

.profile-container header .user {
    width: 50px;
    border-radius: 50%;
    margin-left: 20px;
}

.profile-container header img {
    width: 80px;
}

.profile-container header .button {
    width: 260px;
    margin-left: auto;
    margin-top: 0;
}

.profile-container header button {
    height: 60px;
    width: 60px;
    border-radius: 4px;
    border: 1px solid #fff;
    background: transparent;
    margin-left: 16px;
    transition: border-color 0.2s;
}

.profile-container header button:hover {
    border-color: #999;
}