.details-container{
    width: 100%;
    max-width: 1180px;
    padding: 0 30px;
    margin: 32px auto;
}

.details-resume{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.details-container h1{
    color: #fff;
    margin-top: 50px;
    margin-bottom: 24px;
}

.details-about p {
    margin-bottom: 5px;
}

.details-container section {
    background: #fff;
    padding: 24px;
    border-radius: 5px;
}
