  .list {
    background: #fff;
    border-radius: 2px;
    list-style: none;
    padding: 10px 20px;
  }

  .list button {
    height: 60px;
    width: 60px;
    border-radius: 4px;
    border: 1px solid #fff;
    background: transparent;
    transition: border-color 0.2s;
}
  
  .list_info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px;
    padding-bottom: 5px;
    padding-top: 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .list_info .user {
    display: flex;
  }

  .list-item:last-child {
    border-bottom: none;
  }
  .list-item img {
    border-radius: 50%;
    width: 50px;
  }
  .list-item-content {
    margin-left: 20px;
  }
  .list-item-content h4, .list-item-content p {
    margin: 0;
  }
  .list-item-content h4 {
    margin-top: 10px;
    font-size: 18px;
  }
  .list-item-content p {
    margin-top: 5px;
    color: #aaa;
  }